import React from 'react';
import MainScreen from './components/Layouts/MainScreen/MainScreen';
import WhatsNew from './components/Layouts/WhatsNew/WhatsNew.js';
import {
    HashRouter as Router, 
    Routes, 
    Route
  } from 'react-router-dom';
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ComingSoon from './components/Layouts/ComingSoon/ComingSoon';
import Error404 from './components/Layouts/Error404/Error404';
import HowitWorks from './components/Layouts/HowitWorks/HowitWorks';
const App = () => {
 return(
<>
<Router>

<Routes>
  <Route exact path="/" element={<MainScreen />} />
  <Route path="/whatsnew" element={<WhatsNew />} />
  <Route path="/howitworks" element={<HowitWorks />} />
  <Route path="/pricing" element={<ComingSoon />} />
  <Route path="/jewel" element={<ComingSoon />} />
  <Route path="/rest-api" element={<ComingSoon />} />
  <Route path="/docs" element={<ComingSoon />} />
  <Route path="/support" element={<ComingSoon />} />
  <Route path="/release-notes" element={<ComingSoon />} />

  <Route path="*" element={<Error404 />} />

</Routes>
</Router>
{/* <ComingSoon /> */}
</>
 );
};

export default App;

