import './Gems.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "../../UI/Card/Card";
import pyp from "../../../assets/pyp.svg";
import jar from "../../../assets/jar.svg";
import jewel from "../../../assets/Jewel.svg";


const responsive = {
   desktop: {
     breakpoint: { max: 3000, min: 1024 },
     items: 3,
     slidesToSlide: 3 // optional, default to 1.
   },
   tablet: {
     breakpoint: { max: 1024, min: 575 },
     items: 2,
     slidesToSlide: 2 // optional, default to 1.
   },
   mobile: {
     breakpoint: { max: 575, min: 0 },
     items: 1,
     slidesToSlide: 1 // optional, default to 1.
   }
 };
 const readMore=()=>{
}
 var data=[{"text":`Python based Test Automation Framework is equipped with Rich Libraries for Reporting and multiple test executions. Gempyp also supports features like API Automation and Data Validation.`,"image":pyp,"key":'1',"url":"Gempyp"},
 {"text":`Java Based Test Automation Framework provides out of the box and customized automation solutions for UI and Backend. It also offers a Codeless Approach for API automation and well-defined Reporting Library.`,"image":jar,"key":'2',"url":"Gemjar"},
 {"text":`Jewel is a test management web solution that offers test suite creation using Gempyp and Gemjar, test execution with maximum parallelisation and load balancing along with live and analytical reporting for any framework integrated via Rest APIs.`,"image":jewel,"key":'3',"url":"Jewel"},
 

];
const createCarousel = () =>{
   const final= data.map(mapData=>(
      <div  key={mapData.key} >
   <Card  title={mapData.title} style={{minHeight:'380px',padding:'10%',borderRadius:'16px'}}   image={mapData.image} text={mapData.text} link="Read more" href={mapData.url}/>
</div>
   ));
   return final;
}

 
 
function Gems(props) {
    return (<>
    <div className='text-center' >
       <span className='headtext'>Various Gems</span>
    <br />
       <div className='under'>WHAT WE DO</div>

       </div>

       <div className="container mt-2">
       <Carousel
  swipeable={true}
  draggable
  showDots={true}
  responsive={responsive}
  sliderClass="slide"
  infinite={true}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile","desktop"]}
  deviceType={props.deviceType}
  dotListClass='dots'
  itemClass="items"

>

{/* <div className="d-flex align-items-stretch"> */}
{createCarousel()}
{/* </div> */}

</Carousel>

</div>
    </>  );
}

export default Gems;