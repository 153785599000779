import './HeroDesktop.css';
import gemecohead from '../../../assets/Gemecohead.svg';
import heroimage from '../../../assets/heroimage.png';
import heropyp from '../../../assets/heropyp.svg';
import herojar from '../../../assets/herojar.svg';
import jewel from '../../../assets/jewellogo.svg';
import * as HttpService from '../../../services/HttpService';
function HeroDesktop() {
    return (
        <>
            <div className=" cardHero mb-5 py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 col-md-6 my-5 ">
                            <div className='d-flex justify-content-around mb-3'>
                                <img className='homeBanner1' src={gemecohead} />
                            </div>
                            <div className="d-flex justify-content-around align-items-center">
                                <img className="clickable" src={heropyp} onClick={() => { window.open(`${HttpService.getGempypBaseUrl()}`, "_blank") }} />
                                <img className="clickable pt-1" src={jewel} onClick={() => { window.open(`${HttpService.getJewelBaseUrl()}`, "_blank") }} />
                                <img className="clickable" src={herojar} onClick={() => { window.open(`${HttpService.getGemjarBaseUrl()}`, "_blank") }} />
                            </div>


                        </div>
                        <div className="col-lg-6 col-sm-12 col-md-6 my-5 d-flex justify-content-center">
                            <img className='imageHero' src={heroimage} />
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default HeroDesktop;