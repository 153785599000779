import './WhatsNew.css';
import NavbarMain from '../../UI/NavbarMain/NavbarMain';
import logo from '../../../assets/LOGO.svg';
import Carousel from "react-multi-carousel";
import pyp from '../../../assets/whatsnewpyp.svg';
import jar from '../../../assets/whatsnewjar.svg';
import prf from '../../../assets/whatsnewprf.svg';
import {useState,useEffect} from 'react';
import WhatsnewFramework from '../../Pages/WhatsNewPage/WhatsnewFramework';
import Footer from '../../UI/Footer/Footer';
import ScrolltoTop from '../../UI/ScrolltoTop/ScrolltoTop';
import axios from 'axios';
import * as HttpService from '../../../services/HttpService';
const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 575 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 575, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

 

const WhatsNew = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    // var jardata = [
    //   [
    //     "25 April 2021" ,
    //     "1.0.7" , 
    //     [
    //       "Use of Single Config JSON file." , 
    //       "Enhancements to run Suite with or without bridge token." , 
    //       "Combined all TestNG Bases classes into one TestNG base class." , 
    //       "User Do not need to extend Cucumber base class." ,
    //       "Users can directly access Json from the Resources folder"
    //     ],
    //     "true"
    //   ],
    //   [
    //     "25 April 2021" ,
    //     "1.0.6" , 
    //     [
    //       "Add support to the Cucumber Framework." ,
    //       "Added health check Functionality for codeless automation with post Validation functions."
    //     ],
    //     "false"
    //   ]
    // ] 

    // var pypdata = [
    //   [
    //     "25 April 2021" ,
    //     "0.1.2" , 
    //     [
    //       "Visuals of Report", 
    //       "Feature of API comparison." , 
    //       "Running testcases of same set or category." , 
    //       "Running suite even without bridgetoken."
    //     ],
    //     "true"
    //   ],
    // ]




  const[initialRelease,setInitialRelease] = useState([]);
  
    const[selected,setSelected] = useState("GemPyP");
    
    const[data,setData]=useState([]);
    const[version_data,setversion_data]=useState([]);

  useEffect(() => {
    // console.log(HttpService.getBaseUrl());
     axios.get(HttpService.getBaseUrl()+'intro?gem=whats-new').then( data=>{
      //  console.log(data.data.data.data[0]["gempyp/gemjar/gemprf"])
      setversion_data(data.data.data);
      setInitialRelease(data.data.data.data[0]["Initial_Release"]["gempyp"]);
       setData(data.data.data.data[0]["gempyp"]);
    });
  },[]);
   




    const dataonSlide=(e)=>{
if(e.currentSlide===2){
  setData(version_data.data[0]["gempyp"]);
  setSelected("GemPyP");
setInitialRelease(version_data.data[0]["Initial_Release"]["gempyp"]);
}else if(e.currentSlide===3){
    setData(version_data.data[0]["gemjar"]);
    setSelected("GemJaR");
    setInitialRelease(version_data.data[0]["Initial_Release"]["gemjar"]);
}
// else if(e.currentSlide===4){
//     setData(prfdata);
//     setSelected("GemPrF");
//     setInitialRelease(version_data.data[0]["Initial_Release"]["gemprf"]);
// }
const element = document.getElementById("frameworkData");
element.scrollIntoView();
}
    return ( <>
   <NavbarMain logo={logo} />
   <div>
   <Carousel
  afterChange={(nextSlide, props)=>{dataonSlide(props) }}
  swipeable={true}
  draggable
  showDots={true}
  responsive={responsive}
  sliderClass="slide"
  infinite={true}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile","desktop"]}
  deviceType={props.deviceType}
  dotListClass='dots'
  itemClass="items-new"
  shouldResetAutoplay={false}

>

<div>

    <img src={pyp} alt={"gempyp"} />
    </div>
    <div>
    <img src={jar} alt={"gemjar"}/>
    </div>

    {/* <div>
    <img src={prf} alt={"gemprf"}/>
    </div> */}

</Carousel>

</div>
<div id="frameworkData">
    <WhatsnewFramework selected={selected} data={data} initialRelease={initialRelease} />
    </div>
   
<ScrolltoTop />
    <div className="footer mt-5">
    <Footer />
    </div>

    </> );
}
 
export default WhatsNew;