import React from 'react';
import './MainScreen.css';
import HeroDesktop from '../../Pages/MainScreenPage/HeroDesktop';
import Framework from '../../Pages/MainScreenPage/Framework';
import Gems from '../../Pages/MainScreenPage/Gems';
import Services from '../../Pages/MainScreenPage/Services';
import Chooseus from '../../Pages/MainScreenPage/Chooseus';
import wave from "../../../assets/wavy.svg";
import wave2 from "../../../assets/wavy2.svg";

import Footer from '../../UI/Footer/Footer';
import NavbarMain from '../../UI/NavbarMain/NavbarMain';
import logo from '../../../assets/LOGO.svg';
import ScrolltoTop from '../../UI/ScrolltoTop/ScrolltoTop';
import { useEffect } from 'react';

const MainScreen = () => {
     useEffect(() => {
          window.scrollTo(0, 0);
     }, []);

     return (
          <>

               <NavbarMain logo={logo} />

               {/* <div  className='heroSection desktop '>        */}
               <HeroDesktop />

               {/* </div> */}
               {/* <div className='herosection mobile'>

     </div> */}

               <div className='whatis'>
                    <Framework />
               </div>

               <div className='gems'>
                    <Gems />
               </div>


               {/* <div className="mt-5">

<img src={wave} />
    
    </div>

     <div className='services' style={{paddingBottom:'10%',paddingTop:'2%'}}>
          <Services />
     </div>

     <div className="mb-2">

<img src={wave2} />
    
    </div> */}


               <div className='chooseus'>
                    <Chooseus />
               </div>


               <div className='footer'>
                    <Footer />
               </div>
               <ScrolltoTop />
          </>
     );
};

export default MainScreen;