import './NavbarMain.css';
import logo from '../../../assets/NavLogo.svg';
import pypdrop from "../../../assets/pypdrop.svg";
import jardrop from "../../../assets/jardrop.svg";
import prfdrop from "../../../assets/prfdrop.svg";
import jewel from '../../../assets/gemjewel.svg';
import * as HttpService from '../../../services/HttpService';


import { Link, NavLink } from 'react-router-dom';
function NavbarMain(props) {

    var navroutes = [
        { module: "HOW IT WORKS", route: "/howitworks" },
        { module: "WHAT'S NEW?", route: "/whatsnew" },
        { module: "PRICING", route: "/pricing" },
    ];

    const createNavLinks = () => {
        const navlink = navroutes.map((nav) => (
            <li className="nav-item col-lg">
                <NavLink className={`nav-link ${window.location.hash.split("#")[1] == nav.route ? "isActiveNav" : ""}`} to={nav.route}>
                    {nav.module}
                </NavLink>
            </li>
        ));
        return navlink
    }
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light text-center">

                {/*tab mode logo */}
                <Link className="ms-4 d-lg-none d-none d-sm-block" to="/">
                    <img src={logo} alt="button" />
                </Link>
                {/* mobile view logo */}
                <Link className="d-sm-none" to="/" style={{ margin: 'auto auto' }}>
                    <img src={logo} alt="button" />
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ marginLeft: '34px', marginRight: '34px' }}>
                    <ul className="navbar-nav nav-fill w-100">
                        {/* <div className="row"> */}
                        <li className="nav-item col-md d-none d-lg-block">
                            <Link className="nav-link" to="/">
                                <img src={logo} alt="button" />
                            </Link>
                        </li>
                        <li className="nav-item dropdown col-lg">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                GEMS
                            </a>
                            <div className="dropdown-menu mx-auto text-center" style={{ width: 'fit-content' }} aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href={`${HttpService.getGempypBaseUrl()}`} target="_blank">
                                    <div><span><img src={pypdrop} className="dropimg" /></span><span className='ms-2'>GEMPYP</span></div>
                                </a>
                                <a className="dropdown-item" href={`${HttpService.getGemjarBaseUrl()}`} target="_blank" >
                                    <div><span><img src={jardrop} className="dropimg" /></span><span className='ms-2'>GEMJAR</span></div>
                                </a>
                                <a className="dropdown-item" href={`${HttpService.getJewelBaseUrl()}`} target="_blank">
                                    <div className='me-2'><span><img src={jewel} className="dropimg" /></span><span className='ms-2'>JEWEL  </span></div>
                                </a>
                            </div>
                        </li>
                        {createNavLinks()}
                        {/* <li className="nav-item col-lg">
                                <Link className="nav-link" to="/howitworks">HOW IT WORKS </Link>
                            </li>
                            <li className="nav-item col-lg">
                                <Link className="nav-link" to="/whatsnew">WHAT'S NEW?</Link>
                            </li>
                            <li className="nav-item col-lg">
                                <Link className="nav-link" to="/pricing">PRICING </Link>
                            </li> */}
                        <li className="nav-item col-lg">
                            <a className="nav-link" href={`${HttpService.getJewelBaseUrl()}`} target="_blank">JEWEL LOGIN</a>
                        </li>
                        <li className="nav-item col-lg">
                                <Link className={`nav-link ${window.location.hash.split("#")[1] == "/rest-api" ? "isActiveNav" : ""}`} to="/rest-api">JEWEL REST APIs </Link>
                            </li> 
                    </ul>
                </div>
            </nav>
        </>);
}

export default NavbarMain;