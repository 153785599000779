import './WhatsnewFramework.css';
import WhatsnewCard from './WhatsnewCard.js';

const WhatsnewFramework = (props) => {
const createTimeline=(data)=>{
const final = data.map((propdata,index)=>(
    index<=4 ? 
      <div className={index%2==0?'containerline left':'containerline right'}>
        <WhatsnewCard content={propdata} index={index} />
      </div>  
    : null
));
return final;
}

    return ( <>
    <div className="container">
        <div className="headerFrame">
    {props.selected
    ? <div>
       <span className="selectedFramework">{props.selected} </span>
       <br />
       {
       props.initialRelease?
       <span className="initialRelease">INITIAL RELEASE - {props.initialRelease} </span>
       :null
    }
    </div>

    :null
    
    }
    </div>




    {props.data?
    <div className="time">
        <div className="timeline mt-3">
{createTimeline(props.data)}
  {/* <div className="containerline left">
    <div className="content">
      <h2>2017</h2>
      <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.</p>
    </div>
  </div>
  <div className="containerline right">
    <div className="content">
      <h2>2016</h2>
      <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.</p>
    </div>
  </div>
  <div className="containerline left">
    <div className="content">
      <h2>2017</h2>
      <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.</p>
    </div>
  </div>
  <div className="containerline right">
    <div className="content">
      <h2>2016</h2>
      <p>Lorem ipsum dolor sit amet, quo ei simul congue exerci, ad nec admodum perfecto mnesarchum, vim ea mazim fierent detracto. Ea quis iuvaret expetendis his, te elit voluptua dignissim per, habeo iusto primis ea eam.</p>
    </div>
  </div> */}
  
</div>

       
        </div>
    :null
    }
    </div>
    </> );
}
 
export default WhatsnewFramework;