import './Card.css';
import { Link } from 'react-router-dom';
import * as HttpService from '../../../services/HttpService';



function Card(props) {


    const getHref = (href) => {
        switch (href) {
            case 'Gempyp':
                return `${HttpService.getGempypBaseUrl()}`;
                break;

            case 'Gemjar':
                return `${HttpService.getGemjarBaseUrl()}`;
                break;

            case 'Gemprf':
                return `${HttpService.getGemprfBaseUrl()}`;
                break;

            case 'Jewel':
                return `${HttpService.getJewelBaseUrl()}`;
                break;
            default :
                return "https://gemecosystem.com"
                    
        }
    }
    return (<>
        <div className='card customcard' style={props.style}>
            <div className="card-body text-center py-0">
                {
                    props.image ?
                        <div className="mt-2 mb-3">

                            <img src={props.image} alt="icon" />
                        </div> : null
                }
                {
                    props.title ? <div className={`title-text mt-2 ${props.cardsId ? 'choose-cardsTitle' : ''}  ${props.frameworkCard ? 'framework-cardsTitle' : ''}`}>
                        {props.title}
                    </div> : null
                }

                {
                    props.text ? <div className={`card-text mt-2 ${props.cardsId ? 'choose-cardsBody' : ''}  ${props.frameworkCard ? 'framework-cardsBody' : ''}`}>
                        {props.text}
                    </div>
                        : null
                }
                {
                    props.navigation ? <div className="mt-3"> <Link to={props.route !== undefined ? props.route : '/'}>
                        <img src={props.btnicon} className="btn" alt="button" />
                    </Link> </div> :
                        null
                }

                {/* {props.button ? console.log("yesss") : null} */}
                {
                    props.button ? <button onClick={props.onClick ? () => props.onClick() : () => console.warn('No onClick Function Passed')} className="btn btn-primary mt-3" style={{ backgroundColor: '#3D64D3' }}>Read More</button>
                        : null
                }

                {props.href ? console.log(props.href) : null}
                {props.link ? console.log(props.link) : null}
                {props.link ? <div className="mt-3 linkStyle">
                    <a href={props.href ? getHref(props.href) : "/"} target="_blank" >{props.link}</a>
                </div> : null}

            </div>
        </div>
    </>);
}

export default Card;