import React from 'react'
import './Error404.css';
import image_404 from '../../../assets/404image.svg';
import { Link } from 'react-router-dom';

function Error404() {
    return (
        <>
            <div className="container-fluid" style={{ marginTop: '115px' }}>
                <div className="row">
                    <div className="col-sm-12 mb-4 error text-center">404</div>
                    <div className="col-sm-12 mt-5 notfound text-center">Page not found</div>
                </div>
            </div>
            <div className="mt-5 mb-2"><img src={image_404} style={{ width: '100%' }} /></div>
            <div className="mt-5 text-center px-3 txt">We’re sorry, the page you requested could not be found !</div>
            <div className="text-center mt-3">
                <Link to="/">
                    <button type="button" class="btn btn-primary px-4 py-2" style={{backgroundColor:'#3D64D3' ,borderRadius: '5px'}}>Go Home</button>
                </Link>
            </div>
        </>
    )
}

export default Error404