import React from 'react'
import NavbarMain from '../../UI/NavbarMain/NavbarMain';
import Footer from '../../UI/Footer/Footer';
import comingSoonImg from '../../../assets/Comingsoon.svg';
import './ComingSoon.css';
import fb_icon from '../../../assets/icon_fb.svg';
import twitter_icon from '../../../assets/icon_twitter.svg';
import insta_icon from '../../../assets/icon_insta.svg';
import linkedin_icon from '../../../assets/icon_linkedin.svg';
import {useEffect} from 'react';


function ComingSoon() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
        <NavbarMain/>
        <section className="heroSoon">
        <div className="container">
            <div className="row mb-5">
                <div className="left col-md-6 order-md-1 order-sm-last">
                    <div className="mainheader align-self-center" >
                        <div className="headingtext">
                            Hold Tight!
                        </div>
                        <div className="headingsubtext">
                            This Page is Coming Soon
                        </div>
                    </div>
                    <div className="paraText">
                        Our scouts are currently working hard building this page !
                    </div>
                    </div>
                <div className="col-md-6 justify-content-center order-md-2 order-sm-first">
                    <img className='img-responsive' src={comingSoonImg} style={{height: '100%',width: '100%'}}/>
                </div>
            </div>
            <div className="row">
            <div className="d-flex justify-content-center text-center mb-4">
                    <a href="https://www.facebook.com/gemecosystem" className='icon_links' target="_blank"><img src={fb_icon}/></a>
                    <a href="https://twitter.com/gemecosystem" className='icon_links' target="_blank"><img src={twitter_icon}/></a>
                    <a href="https://www.instagram.com/gemecosystem/" className='icon_links' target="_blank"><img src={insta_icon}/></a>
                    <a href="https://www.linkedin.com/company/gemecosystem/" className='icon_links' target="_blank"><img src={linkedin_icon}/></a>
                </div>
            </div>
        </div>
        </section>
      <Footer/>
    </>
  )
}

export default ComingSoon;