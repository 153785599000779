import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import '../src/index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import 'bootstrap/dist/js/bootstrap.js';
import * as Environment from './services/Environment';
import * as HttpService from './services/HttpService';


HttpService.setEnv(Environment.checkEnv());
ReactDOM.render(
  
    <App />
  ,
  document.getElementById('root')
);


