


    export const checkEnv=()=>{
        if(window.location.origin.includes("beta.gemecosystem")){
            return "beta";
        }else if(window.location.origin.includes("gemecosystem")){
            return "prod";
        }else{
            return "dev";
        }
    }