import './WhatsnewCard.css';
const WhatsnewCard = ({content,index}) => {
    const createVersionPointers = (data) => {
        const pointers = data.map((pointer) => (
            <li>{pointer}</li>
        ))
        return pointers;
    }
    return ( <>
   <div className="cardMain">
<div className="d-flex justify-content-between">
    <div className="title ms-2 mt-3">
        <h6>{content[0]} </h6>
    </div>
    <div className={'btn px-3 py-2 ' + (content[3]==="true"?'activeVersion':'inactiveVersion')}>
     <strong>{content[1]}</strong> 
    </div>
</div>
<div className="ms-2 py-4 mb-4">
    <ul>
    {createVersionPointers(content[2])}
    </ul>
</div>
   </div>
    
    </> );
}
 
export default WhatsnewCard;