import './HowitWorks.css';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import NavbarMain from '../../UI/NavbarMain/NavbarMain';
import heroimage from '../../../assets/howitworksHero.png';
import WhyhowitWorks from '../../Pages/HowitWorksPage/WhyhowitWorks';
import Footer from '../../UI/Footer/Footer';
import withoutGem_Banner from '../../../assets/withoutGem_Banner.png';
import withGem_Banner from '../../../assets/withGem_Banner.png';
import ParallelHowGraph from '../../../assets/ParallelHowGraph.svg';
import ParallelHowWithout from '../../../assets/ParallelHowWithout.svg';
import ParallelHowWith from '../../../assets/ParallelHowWith.svg';
// import ba from '../assets/howitworksba.svg';
import beforeafter from '../../../assets/howitworksbefore.svg';
import ScrolltoTop from '../../UI/ScrolltoTop/ScrolltoTop';
import * as HttpService from '../../../services/HttpService';
import {
    faChevronDown,
    faCircleChevronDown,
    faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";

const HowitWorks = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const howItWorksSteps = {
        "steps": [
            {
                "heading": "Setup Test",
                "svgImg": "setuptest.svg",
                "pointers": ["Select and Install Framework of your favourite  language - Java Python JavaScript based on your needs.",
                    "Write business logic of test- Just fill configs files with test data or write code for custom needs.",
                    "Run and Debug and find debug result files locally.",
                    "Add Bridge Token of your user to integrate with Jewel Cloud.",
                    "Execute on your infrastructure- Mac, Linux, Windows or connect with Jewel APIs for Cloud Runs."
                ]
            },
            {
                "heading": "Execution - Jewel UI",
                "svgImg": "executionJewel.svg",
                "pointers": ["Create testcases on Jewel UI rather than IDE.",
                    "Hassle-free integration of existing test suites and their configs.",
                    "Manage and Update test case and test data.",
                    "Execute Test Suite or Set of Suite by just a click on Jewel Infra.",
                    "Get maximum Parallelisation   with Jewel Infra"
                ]
            },
            {
                "heading": "Reporting - Jewel UI",
                "svgImg": "reportingJewel.svg",
                "pointers": ["View live running execution report of test suite runs -both from your infra and jewel UI.",
                    "View execution report and analysis the results with multiple filters and details.",
                    "Create multiple dashboard of report to analysis the results over the time.",
                    "Visualise the impact on your application based various analytical Jewel parameters  like App Stability Score, Automation Stability Score, Downtime, Broken Index etc.",
                    "Introduce transparency within hierarchy by using results from Jewel Parameters.",
                    "Share reports easily securely among team members just by click."
                ]
            }
        ]
    }


    const createPointers = (pointers) => {
        const mapPoints = pointers.map((dataEle) => (
            <div className='ms-2 mt-3 d-flex'>
                <div className='me-1'>
                    <FontAwesomeIcon
                        className="mx-2 circleCheckIcon"
                        icon={faCircleCheck}
                    />
                </div>
                <div>{dataEle}</div>
            </div>
        ))
        return mapPoints;
    }
    const createSection = (data) => {
        const rowSec = data["steps"].map((step, i) => (
            <>
                <div className={`row py-2 ${(i % 2 == 0) ? "" : "flex-row-reverse"}`}>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex align-items-center justify-content-center'>
                        <div className='customCard d-flex align-items-center'>
                            <div>
                                <div className='howItHead mt-5 mb-3 ps-3 ms-3'>{step.heading}</div>
                                <div className='howItData mb-5 px-3'> {createPointers(step.pointers)}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex align-items-center justify-content-center p-5'>
                        <img src={require("../../../assets/" + step.svgImg)} alt="View Report" className='mb-2 img-fluid'></img>
                    </div>
                </div>
            </>
        ))
        return rowSec;
    }

    return (<>
        <ScrolltoTop />
        <NavbarMain />
        <section>
            <div className="cardHero mb-5 py-4">
                <div className="container">
                    <div className="row px-3 d-flex">
                        <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                            <img src={withoutGem_Banner} className="howBanner" />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                            <img src={withGem_Banner} className="howBanner" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="scrollToKnowMore">
            <div className='container text-center afterHero pb-3'>
                {createSection(howItWorksSteps)}
            </div>
        </section>

        <section className='cardHero'>
            <div className='container-fluid pt-5 text-center'>
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-12 d-flex my-5 justify-content-center">
                        <img className='parallelImg img-fluid' src={ParallelHowWithout}/>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 d-flex my-5 justify-content-center">
                        <img className='parallelImg img-fluid' src={ParallelHowWith}/>
                    </div>
                </div>
                <div className='pb-4 pt-md-5 pt-lg-0'>
                    <img className='img-fluid' src={ParallelHowGraph}/>
                    <div className='parallelText mt-2'>67% Reduction in Time</div>
                </div>
            </div>
        </section>

        <section>
            <Footer />
        </section>
    </>);
}

export default HowitWorks;