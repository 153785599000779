import './Chooseus.css';
import Card from '../../UI/Card/Card';
import Icon1 from '../../../assets/chooseus1.svg';
import Icon2 from '../../../assets/chooseus2.svg';
import Icon3 from '../../../assets/chooseus3.svg';
import Icon4 from '../../../assets/chooseus4.svg';
import Icon5 from '../../../assets/chooseus5.svg';
import Icon6 from '../../../assets/chooseus6.svg';



function Chooseus() {
    return ( <>
    <div className='text-center' >
       <span className='headtext'>What sets GemEcosystem apart ?</span>
    <br />
       <div className='under'>WHY CHOOSE US</div>
       </div>
<div className='container mt-5'>
    <div className='row'>
        <div className='mt-2 col-lg-4 col-sm-6 col-12 d-flex align-items-stretch'>
        <Card style={{maxHeight:'100%',padding:'10%',borderRadius:'16px'}} cardsId='choose-cards' image={Icon1} title="Ready to use Frameworks in Java/Python" text="Gemjar and Gempyp provides a complete wholistic framework and utilities with minimum steps."/>
 
        </div>
        <div className='mt-2 col-lg-4 col-sm-6 col-12 d-flex align-items-stretch'>
        <Card style={{maxHeight:'100%',padding:'10%',borderRadius:'16px'}} cardsId='choose-cards' image={Icon2} title="Integrated End to End Automation" text="Using our frameworks, You can automate end to end scenarios of UI, API and Data Validation etc. in Code/codeless manner."/>
 
        </div>
        <div className='mt-2 col-lg-4 col-sm-6 col-12 d-flex align-items-stretch'>
        <Card style={{maxHeight:'100%',padding:'10%',borderRadius:'16px'}} cardsId='choose-cards' image={Icon3} title="Serverless Cloud Execution - Unlimited Capacity" text="All execution from Test tool used serverless event driven execution hence capacity is unlimited."/>
 
        </div>
        <div className='mt-2 col-lg-4 col-sm-6 col-12 d-flex align-items-stretch'>
        <Card style={{maxHeight:'100%',padding:'10%',borderRadius:'16px'}} cardsId='choose-cards' image={Icon4} title="Live and Analytical Reporting" text="All execution on Jewel can be seen live, Multiple analytics like Scoring provide the Managers end to end health of the project."/>
 
        </div>
        <div className='mt-2 col-lg-4 col-sm-6 col-12 d-flex align-items-stretch'>
        <Card style={{maxHeight:'100%',padding:'10%',borderRadius:'16px'}} cardsId='choose-cards' image={Icon5} title="One Click Suite creation" text="Test suites can be created on Jewel UI which can be shared easily with other users and can be scaled in multiple projects."/>
 
        </div>
        <div className='mt-2 col-lg-4 col-sm-6 col-12 d-flex align-items-stretch'>
        <Card style={{maxHeight:'100%',padding:'10%',borderRadius:'16px'}} cardsId='choose-cards' image={Icon6} title="Test Management Ecosystem" text="All Frameworks are integrated with Jewel Dashboard that makes the test managment easy for executions, reporting and analytics."/>
 
        </div>
    </div>
</div>
        </> );
}

export default Chooseus;