

let env;
let baseUrl;
let pypBaseUrl;
let jarBaseUrl;
let prfBaseUrl;
let jewelBaseUrl;

export function setEnv(val) {
    env = val;
    setBaseUrl(env);
    setGemjarBaseUrl(env);
    setGemprfBaseUrl(env);
    setGempypBaseUrl(env);
    setJewelBaseUrl(env);
}

//set gempyp base url
export const setGempypBaseUrl = (val) => {
    if (val === "prod") {
        pypBaseUrl = "https://gempyp.gemecosystem.com"
    } else {
        pypBaseUrl = "https://gempyp-beta.gemecosystem.com"
    }
}
export const getGempypBaseUrl = () => {
    return pypBaseUrl;
}

//set gemjar base url
export const setGemjarBaseUrl = (val) => {
    if (val === "prod") {
        jarBaseUrl = "https://gemjar.gemecosystem.com"
    } else {
        jarBaseUrl = "https://gemjar-beta.gemecosystem.com"
    }
}
export const getGemjarBaseUrl = () => {
    return jarBaseUrl;
}

//set gemprf base url
export const setGemprfBaseUrl = (val) => {
    if (val === "prod") {
        prfBaseUrl = "https://gemprf.gemecosystem.com"
    } else {
        prfBaseUrl = "https://gemprf-beta.gemecosystem.com"
    }
}
export const getGemprfBaseUrl = () => {
    return prfBaseUrl;
}

//set gempyp base url
export const setJewelBaseUrl = (val) => {
    if (val === "prod") {
        jewelBaseUrl = "https://jewel.gemecosystem.com/#/login"
    } else {
        jewelBaseUrl = "https://jewel-beta.gemecosystem.com/#/login"
    }
}
export const getJewelBaseUrl = () => {
    return jewelBaseUrl;
}



export const setBaseUrl = (val) => {
    if (val === "prod") {
        baseUrl = "https://apis.gemecosystem.com/"
    } else {
        baseUrl = "https://apis-beta.gemecosystem.com/"
    }

    // console.log(baseUrl);
}
export const getBaseUrl = () => {
    return baseUrl;
}