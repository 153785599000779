import './Framework.css';
import Card from '../../UI/Card/Card';
import setup from '../../../assets/framework1.svg';
import write from '../../../assets/framework2.svg';
import run from '../../../assets/framework3.svg';
import report from '../../../assets/framework4.svg';
import button from '../../../assets/btn-round.svg';
function Framework() {
   return (<>

      <div className='text-center' >
         <span className='headtext'>What Is GemEcosystem ?</span>
         <br />
         <div className='under'>ABOUT US</div>

      </div>

      <div className="container mt-5">
         <div className="whatiscontent mt-2 text-center mx-5">
         Gemecosystem is Ecosystem of QA Automation Frameworks , Reporting dashboard, Execution infrastructure build to provide lesser time to market for automation delivery , Accelerate test speed, introduce transparency among stockholders and increased  confidence in results.
         </div>
         <div className='row mt-5 whatis-row'>

            <div className='col-lg-6 col-sm-12 col-12 text-center'>
               <div className='subheadtext'>Out of Box Frameworks</div>
               <div className="subheadtext-border mt-2"></div>
               {/* <div className="d-flex whatis-cols align-items-center"> */}
               <div className='mt-5 mx-4 my-5'>
                  <div className='px-2 mt-2'>
                     <Card style={{ minHeight: '420px', margin: '7%', padding: '6%', borderRadius: '16px' }} image={setup} frameworkCard='frameworkCard' title="Codeless Automation" text="GemEcosystem contains Multiple Out of Box Frameworks in your desired language. It contains a bundle of scripts which reduces the time to market for framework development. Simply configure repetitive tests without writing code using the ready-to-use Framework. For applications specific requirements, code can be customised as per your desired language." />
                  </div>
                  <div className='px-2 mt-2'>
                     <Card style={{ minHeight: '420px', margin: '7%', padding: '6%', borderRadius: '16px' }} image={write} frameworkCard='frameworkCard' title="Setup and Write Scalable Tests" text="With ready to use framework and defined GemEco QA Playbook, write test which can be scaled to any environment and test-data. Our framework supports Functional automation , Regression, Web UI Automation, API Automation, Data Validation. With our framework and playbook you can reduced your time to market by 5X." />
                  </div>
               </div>
               {/* <div className="whatis-borderleft ps-3"></div>
               </div> */}
            </div>

            <div className='col-lg-6 col-sm-12 col-12 text-center'>
               <div className='subheadtext'>Jewel Dashboard </div>
               <div className="subheadtext-border mt-2"></div>
               {/* <div className="d-flex whatis-cols align-items-center"> */}
               <div className='mt-5 mx-4 my-5'>
                  <div className='px-2 mt-2'>
                     <Card btnicon={button} style={{ minHeight: '420px', margin: '7%', padding: '6%', borderRadius: '16px' }} image={run} route="/abc" frameworkCard='frameworkCard' title="Create & Run Tests" text="Create automation of multiple scenario just by few click, integrate your existing automation. Run your test suite on Server-less Jewel Infra which support maximum parallelisation thus reducing the execution time by 5x and no hassle of maintaining any Selenium Grid. View the Live  execution of the Test Suite on our UI." />
                  </div>
                  <div className='px-2 mt-2'>
                     <Card btnicon={button} style={{ minHeight: '420px', margin: '7%', padding: '6%', borderRadius: '16px' }} image={report} route="/" frameworkCard='frameworkCard' title="Analytical Reporting" text="Several reports and Scores available to evaluate the quality of  Application under Test & Automation. Open Rest APIs available to integrate any of your automation framework. Share the report with other team members securely by just a click. See the exact failure reasons and get notified via several Integrations like Jira, Slack , Teams, Emails." />
                  </div>
               </div>
               {/* <div className="whatis-borderright ps-3"></div>
               </div> */}
            </div>

         </div>

      </div>
   </>);
}

export default Framework;