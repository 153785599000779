import './Card.css';
import {Link} from 'react-router-dom';

import {useState,useEffect} from 'react';
import bue from '../../../assets/howitworksbue.svg';
import fa from '../../../assets/howitworksfa.svg';
import oss from '../../../assets/howitworksoss.svg';
import qac from '../../../assets/howitworksqac.svg';


function HowItWorksCard(props) {

    return ( <>
    <div className="card  customcard" style={props.style}>
        <div className="card-body text-center">
            {
                props.image ?
            <div className="mt-2 mb-4">

<img src={require("../../../assets/"+props.image)} alt="icon" />
            </div> :null
}
           { 
           props.title?<div className="title-text mt-2">
                {props.title}
            </div>:null
            }

            {
             props.text?<div className="card-text mt-2">
           {props.text}
            </div>
         :null
         }
             { 
             props.navigation ?<div className="mt-3"> <Link  to={props.route!==undefined ? props.route:'/'}>
<img src={props.btnicon} className="btn" alt="button" /> 
</Link> </div> :
null
}

{
    props.button ? <button onClick={props.onClick?()=>props.onClick():()=>console.warn('No onClick Function Passed')} className="btn btn-primary mt-3" style={{backgroundColor:'#3D64D3'}}>Read More</button>
    :null
}
{props.link? <div className="mt-3 linkStyle">
        <a href={props.href?props.href:"/" } target="_blank" >{props.link}</a>
    </div>:null}
           
        </div>
    </div>
    </> );
}

export default HowItWorksCard;