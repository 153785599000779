import './Footer.css';
import footerlogo from '../../../assets/FooterLogo.svg';
import { Link } from 'react-router-dom';
import facebook from '../../../assets/icon_fb.svg';
import twitter from '../../../assets/icon_twitter.svg';
import instagram from '../../../assets/icon_insta.svg';
import linkedin from '../../../assets/icon_linkedin.svg';
import * as HttpService from '../../../services/HttpService';


function Footer() {
    return (<>
        <div className="footer">

            <div className="container">
                <div className="row gx-5">
                    <div className="col-lg-3 col-sm-6 col-12">
                        <div className="heading-text">

                            <img src={footerlogo} alt="LOGO" style={{ height: '90%', width: '90%', marginRight: '3%' }} />
                            {/* <span style={{marginLeft:'3%'}}>GemEcosystem</span> */}
                        </div>

                        <div className="sub-text mb-2" style={{ marginLeft: '22%' }}>Made with ❤️ in India.</div>
                        <div className="row">
                            <div className="d-flex justify-content-center text-center">
                                <a href="https://www.facebook.com/gemecosystem" className='icon_links_footer' target="_blank"><img src={facebook} /></a>
                                <a href="https://twitter.com/gemecosystem" className='icon_links_footer' target="_blank"><img src={twitter} /></a>
                                <a href="https://www.instagram.com/gemecosystem/" className='icon_links_footer' target="_blank"><img src={instagram} /></a>
                                <a href="https://www.linkedin.com/company/gemecosystem/" className='icon_links_footer' target="_blank"><img src={linkedin} /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                        <div className="heading-text2">
                            Feature
                        </div>
                        <ul className="sub-text list">
                            <li><a href={`${HttpService.getJewelBaseUrl()}`} target="_blank" className='footer_href'>Jewel</a></li>
                            <li><a href={`${HttpService.getGempypBaseUrl()}`} target="_blank" className='footer_href'>GemPYP</a></li>
                            {/* <li><a href={`${HttpService.getGemprfBaseUrl()}`} target="_blank" className='footer_href'>GemPRF</a></li> */}
                            <li><a href={`${HttpService.getGemjarBaseUrl()}`} target="_blank" className='footer_href'>GemJAR</a></li>

                        </ul>
                    </div>

                    <div className="col-lg-2 col-sm-6 col-12">
                        <div className="heading-text2">
                            Company
                        </div>
                        <ul className="sub-text list">
                            <li>
                                <Link to="/docs" className='footer_href'>Docs </Link>
                            </li>
                            <li>
                                <Link to="/support" className='footer_href'>Support </Link>
                            </li>
                            <li>
                                <Link to="/release-notes" className='footer_href'>Release Notes </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="heading-text2">
                            Send Updates
                        </div>
                        <div className="input-group mt-2">
                            <input type="text" className="form-control" placeholder="Enter your email address" aria-label="Enter your email address" aria-describedby="button-addon2" />
                            <button className="btn btn-primary" style={{ backgroundColor: '#3D64D3' }} type="button" id="button-addon2">Subscribe</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>);
}

export default Footer;